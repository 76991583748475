<template>
  <div>
    <v-container class="pb-0">
      <h1 class="my-8">{{ $t("Plans") }}</h1>
      <v-divider class="mb-12" />
      <v-card elevation="4">
        <v-card-text class="pa-0">
          <v-row>
            <v-col>
              <ServicesTable :loading="loading" :services="services" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import Api from "@/apis/Api";
import ServicesTable from "../components/dataIterator/tables/ServicesTable.vue";

export default {
  components: {
    ServicesTable,
  },
  mounted() {
    this.loadServices();
  },
  data() {
    return {
      loading: false,
      services: [],
    };
  },
  computed: {},
  methods: {
    loadServices() {
      this.loading = true;
      Api.get("/services")
        .then((response) => {
          this.services = response.data.data.map((service) => {
            service.instances_count = service.instances.length;
            return service;
          });
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
