<template>
  <v-card elevation="4">
    <v-card-text>
      <div class="row">
        <div class="col">
          <v-data-table
            calculate-widths
            :headers="headers"
            :items="services"
            :items-per-page="-1"
            hide-default-footer
            class="elevation-0 custom-data-table"
            :loading="loading"
          >
            <template v-slot:item.plan_name="{ item }">
              {{ item.plan_name }}
              <v-chip v-if="item.is_trial" color="warning">
                {{ $t("Expires") }}
                {{ moment(item.trial_expiry_at).fromNow() }}
              </v-chip>
            </template>
            <template v-slot:item.instances_count="{ item }">
              {{ item.instances_count }} /
              {{ item.plan_settings.instance_limit }}
            </template>
            <template v-slot:item.instances="{ item }">
              <ul>
                <li v-for="instance in item.instances" :key="instance.id">
                  {{ instance.domain }}
                </li>
              </ul>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn>{{ $t("button.cancel") }}</v-btn>
              <v-btn
                v-if="item.plan_upgrade_link"
                :href="item.plan_upgrade_link"
                target="_blank"
              >
                {{ $t("button.upgrade") }}
              </v-btn>
            </template>
          </v-data-table>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";

export default {
  props: {
    services: Array,
    loading: Boolean,
  },
  data() {
    return {
      moment,
      itemsLoading: false,
      headers: [
        {
          text: this.$t("Plan Name"),
          value: "plan_name",
        },
        {
          text: this.$t("Instance Limit"),
          value: "instances_count",
        },
        {
          text: this.$t("Instances"),
          value: "instances",
        },
        {
          text: this.$t("table.header.actions"),
          value: "actions",
          align: "end",
        },
      ],
      items: [],
    };
  },
};
</script>
